import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Header from "../components/Header";
import api from "../utils/api";
import { MEDIA_URL, API_URL } from "../configs";
import axios from "axios";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { useNavigate, useLocation } from "react-router-dom";

import "../assets/css/style.min.css";
import "../assets/css/icons.min.css";
import "../assets/libs/aos/aos.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const ChatPDF = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [botTyping, setBotTyping] = useState(false); // New state for bot typing
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParam = new URLSearchParams(location.search);
  const documentId = queryParam.get("document_id");

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const handlePreviousPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const handleNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    if (documentId) {
      const pdfUrl = `${MEDIA_URL}/${documentId}.pdf`;
      setPdfUrl(pdfUrl);
    }
  }, [navigate]);

  const handlePDFUpload = async (event) => {
    event.preventDefault();
    setProcessing(true);
    if (!pdfFile) return;
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);

    try {
      const token = localStorage.getItem("token");
      // const response = await axios.post("https://api.ragminds.com/upload-pdf/", formData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response = await fetch(`${API_URL}/upload-pdf/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      
      if (response.ok) {
        setPdfUrl(URL.createObjectURL(pdfFile));
        const data = await response.json();
        setProcessing(false);
        navigate(`?document_id=${data.document_id}`);
      } else {
        setProcessing(false);
        alert("Failed to upload PDF. Please try again.");
      }
    } catch (error) {
      setProcessing(false);
      alert("Failed to upload PDF. Please try again.");
      console.error("Error uploading PDF:", error);
    }
  };

  const handleSendMessage = async () => {
    if (!userMessage) return;

    setChatMessages((prev) => [...prev, { text: userMessage, isUser: true }]);
    setUserMessage("");
    setBotTyping(true); // Start bot typing

    const formData = new FormData();
    formData.append("question", userMessage);
    formData.append("document_id", documentId);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${API_URL}/ask-question/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setChatMessages((prev) => [
        ...prev,
        { text: response.data.answer.answer, isUser: false },
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setBotTyping(false); // Stop bot typing
    }
  };

  return (
    <div className="bg-default-900">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <section className="py-20">
        <div className="container text-center">
          <h1 className="text-4xl font-medium text-white mb-6">
            Chat with Your PDF
          </h1>
          <p className="text-lg text-default-300 mb-10">
            Upload a PDF and interact with its content using our AI-powered chatbot.
          </p>

          {/* PDF Upload Form */}
          <div className="max-w-xl mx-auto bg-default-950/40 p-8 rounded-lg backdrop-blur-3xl mb-8">
            <label className="text-lg text-white mb-2 block">Upload Your PDF</label>
            <input
              type="file"
              accept="application/pdf"
              onChange={(e) => setPdfFile(e.target.files[0])}
              className="block w-full p-3 rounded-lg bg-default-700 text-white border border-default-400 mb-4"
              required
              disabled={processing}
            />
            <button className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300" onClick={handlePDFUpload}>
              {processing ? "Processing..." : "Upload PDF"}
            </button>
          </div>

          {/* PDF Preview and Chat Section */}
          {pdfUrl && (
            <div className="flex gap-6">
              {/* PDF Viewer */}
              <div className="bg-default-950/40 p-4 rounded-lg backdrop-blur-3xl" style={{ flexBasis: "50%" }}>
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} width={500} />
                </Document>
                <div className="flex justify-between mt-4">
                  <button
                    onClick={handlePreviousPage}
                    disabled={pageNumber <= 1}
                    className="bg-primary text-white py-2 px-4 rounded-lg disabled:opacity-50 hover:bg-primary-hover"
                  >
                    Previous
                  </button>
                  <p className="text-white">
                    Page {pageNumber} of {numPages}
                  </p>
                  <button
                    onClick={handleNextPage}
                    disabled={pageNumber >= numPages}
                    className="bg-primary text-white py-2 px-4 rounded-lg disabled:opacity-50 hover:bg-primary-hover"
                  >
                    Next
                  </button>
                </div>
              </div>

              {/* Chat Interface */}
              <div className="bg-default-950/40 p-4 rounded-lg backdrop-blur-3xl" style={{ flexBasis: "50%" }}>
                <div className="bg-default-800 p-4 rounded-lg h-[400px] overflow-y-auto mb-4 text-default-200">
                  {chatMessages.map((msg, index) => (
                    <div key={index} className={msg.isUser ? "text-right mb-2" : "text-left mb-2"}>
                      <strong>{msg.isUser ? "You" : "Me(pdf)"}:</strong> {msg.text}
                    </div>
                  ))}
                  {botTyping && (
                    <div className="text-left mb-2">
                      <strong>Me(pdf):</strong> <i>Typing...</i>
                    </div>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={userMessage}
                    onChange={(e) => setUserMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSendMessage();
                      }
                    }}
                    className="flex-1 p-3 rounded-lg bg-default-700 text-white border border-default-400"
                    placeholder="Type your message here..."
                  />
                  <button
                    onClick={handleSendMessage}
                    className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300"
                    disabled={botTyping}
                  >
                    {botTyping ? "..." : "Send"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ChatPDF;
