import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("username");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    navigate("/login");
  };

  return (
    <header className="bg-default-900 p-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="text-white font-semibold text-xl">
          <a href="/" className="text-white hover:text-primary transition">
            RAGMinds
          </a>
        </div>
        
        <div className="flex items-center gap-4">
          <p className="text-white">Welcome, {username || "Guest"}!</p>
          <button
            onClick={handleLogout}
            className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-hover transition duration-300"
          >
            Logout
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
