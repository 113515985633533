import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";
import axios from "axios";
import { API_URL } from "../configs";

import "../assets/css/style.min.css";
import "../assets/css/icons.min.css";
import "../assets/libs/aos/aos.css";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setErrorMessage(null); // Clear previous errors
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match. Please try again.");
      return;
    }
    try {
      await axios.post(`${API_URL}/signup`, {
        username,
        password,
        email,
        mobile,
      });
      navigate("/login");
    } catch (error) {
      // Display error message from API response
      if (error.response && error.response.data && error.response.data.detail) {
        // console.log requested url
        console.log(error.response.config.url);
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage("Signup failed. Please try again.");
      }
    }
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.signupContainer}>
        <h2 style={styles.heading}>Sign up</h2>
        {errorMessage && (
          <div style={styles.errorAlert}>{errorMessage}</div>
        )}
        <form onSubmit={handleSignup} style={styles.form}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="tel"
            placeholder="Mobile Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={styles.input}
            required
          />
          <button type="submit" style={styles.button}>
            Sign up
          </button>
        </form>
        <p className="mt-4 text-center">
          Already have an account?{" "}
          <a href="/login" className="text-primary hover:underline">
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#1a202c", // Dark background color for a professional look
  },
  signupContainer: {
    width: "100%",
    maxWidth: "400px",
    padding: "2rem",
    backgroundColor: "#2d3748",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  heading: {
    marginBottom: "1.5rem",
    fontSize: "1.5rem",
    color: "#e2e8f0", // Light gray text color
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: "0.75rem",
    marginBottom: "1rem",
    borderRadius: "4px",
    border: "1px solid #4a5568",
    backgroundColor: "#2d3748",
    color: "#e2e8f0",
    fontSize: "1rem",
  },
  button: {
    padding: "0.75rem",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#3182ce",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  errorAlert: {
    backgroundColor: "#e53e3e",
    color: "#fff",
    padding: "0.75rem",
    borderRadius: "4px",
    marginBottom: "1rem",
    fontSize: "0.875rem",
    textAlign: "center",
  },
};

export default Signup;
