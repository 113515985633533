import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Navbar() {
    const { t } = useTranslation();
    return (
        <>
            <div>
                <div className="fixed top-0 -z-10">
                    <div className="blur-[200px] w-[500px] h-[500px] rounded-full bg-gradient-to-tl from-red-600/40 to-pink-600/40"></div>
                </div>
                <div className="fixed top-0 end-0 -z-10">
                    <div className="blur-[200px] w-[500px] h-[500px] rounded-full bg-gradient-to-tl from-red-600/40 to-pink-600/40"></div>
                </div>
                <div className="fixed inset-0 flex items-center justify-center -z-10">
                    <div className="blur-[200px] w-[500px] h-[500px] rounded-full bg-gradient-to-tl from-red-600/40 to-pink-600/40"></div>
                </div>
                <div className="fixed bottom-0 start-0 -z-10">
                    <div className="blur-[200px] w-[500px] h-[500px] rounded-full bg-gradient-to-tl from-red-600/40 to-pink-600/40"></div>
                </div>
                <div className="fixed bottom-0 end-0 -z-10">
                    <div className="blur-[200px] w-[500px] h-[500px] rounded-full bg-gradient-to-tl from-red-600/40 to-pink-600/40"></div>
                </div>
            </div>

            <header id="navbar-sticky" className="navbar">
                <div className="container">
                    <nav>
                        <a href="/" className="logo">
                            {/* <img src="assets/images/logo2-t.png" className="h-10" alt="RAGMinds Logo"> */}
                            <h1 className="text-2xl font-medium text-white">RAGMinds</h1>
                        </a>

                        <div className="lg:hidden flex items-center ms-auto px-2.5">
                            <button className="hs-collapse-toggle inline-flex items-center justify-center h-9 w-12 rounded-md border border-white/20 bg-default-100/5" type="button" id="hs-unstyled-collapse" data-hs-collapse="#mobileMenu" data-hs-type="collapse">
                                <i data-lucide="menu" className="h-5 w-5 stroke-white"></i>
                            </button>
                        </div>

                        <div id="mobileMenu" className="hs-collapse transition-all duration-300 lg:basis-auto basis-full grow hidden lg:flex items-center justify-center mx-auto mt-2 lg:mt-0">
                            <ul id="navbar-navlist" className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="#home">{t("Home")}</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href="#tools">{t("Tools")}</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href="#features">{t("Features")}</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href="#contact">{t("Contact")}</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </>
        
    );
}

export default Navbar;
